import { useContext, useMemo } from 'react';
import pick from 'lodash/fp/pick';
import dynamic from 'next/dynamic';

import { stripCurrency } from '~/shared/services/products';
import ProductsContext from '~/shared/providers/ProductsContext';
import Sections from '~/shared/components/Sections';
import {
  ComponentsType,
  SectionType,
} from '~/shared/components/Sections/types';
import HeroFull from '~/shared/components/Hero/HeroFull';
import HeroFramed from '~/shared/components/Hero/HeroFramed';
import FullMedia from '~/shared/components/FullMedia';
import { FlattenedNinetailedExperience } from '~/shared/services/contentful';

export interface Section extends SectionType {
  nt_experiences?: FlattenedNinetailedExperience[];
}

export type SectionsListProps = {
  sections?: Section[];
  activeProducts?: string[];
};

const aboveTheFoldComponents = {
  hero: HeroFull,
  heroNew: HeroFramed,
  fullMedia: FullMedia,
} as ComponentsType;

const componentsMap = {
  ...aboveTheFoldComponents,
  gallery: dynamic(
    () =>
      import(
        /* webpackChunkName: 'component-gallery' */ '~/shared/components/Gallery'
      ),
  ),
  longText: dynamic(
    () =>
      import(
        /* webpackChunkName: 'component-longText' */ '~/shared/components/LongText'
      ),
  ),
  partnerLogos: dynamic(
    () =>
      import(
        /* webpackChunkName: 'component-partnerLogos' */ '~/shared/components/PartnerLogos'
      ),
  ),
  pricingTable: dynamic(
    () =>
      import(
        /* webpackChunkName: 'component-pricingTabke' */ '~/shared/components/PricingTable'
      ),
  ),
  cardSchemes: dynamic(
    () =>
      import(
        /* webpackChunkName: 'component-cardSchemes' */ '~/shared/components/CardSchemes'
      ),
  ),
  groupedCardSchemesSection: dynamic(
    () =>
      import(
        /* webpackChunkName: 'component-groupedCardSchemesSection' */ '~/shared/components/GroupedCardSchemes'
      ),
  ),
  finePrint: dynamic(
    () =>
      import(
        /* webpackChunkName: 'component-finePrint' */ '~/shared/components/FinePrint'
      ),
  ),
  termsAndConditions: dynamic(
    () =>
      import(
        /* webpackChunkName: 'component-termsAndConditions' */ '~/shared/components/FinePrint'
      ),
  ),
  captionedShowcase: dynamic(
    // @ts-expect-error Component needs TS rewrite due to prop-types related TS error
    () =>
      import(
        /* webpackChunkName: 'component-captionedShowcase' */ '~/shared/components/CaptionedShowcase'
      ),
  ),
  benefitList: dynamic(
    () =>
      import(
        /* webpackChunkName: 'component-benefitList' */ '~/shared/components/BenefitList'
      ),
  ),
  callToAction: dynamic(
    () =>
      import(
        /* webpackChunkName: 'component-callToAction' */ '~/shared/components/CallToAction'
      ),
  ),
  pricingCardsBr: dynamic(
    () =>
      import(
        /* webpackChunkName: 'component-pricingCardsBr' */ '~/shared/components/PricingCardsBr'
      ),
  ),
  pricingCards: dynamic(
    () =>
      import(
        /* webpackChunkName: 'component-pricingCards' */ '~/shared/components/PricingCards'
      ),
  ),
  pricingCardsWithCardSchemes: dynamic(
    () =>
      import(
        /* webpackChunkName: 'component-pricingCardsWithCardSchemes' */ '~/shared/components/PricingCardsWithCardSchemes'
      ),
  ),
  planComparison: dynamic(
    () =>
      import(
        /* webpackChunkName: 'component-planComparison' */ '~/shared/components/PlanComparison'
      ),
  ),
  costsList: dynamic(
    () =>
      import(
        /* webpackChunkName: 'component-costsList' */ '~/shared/components/CostList'
      ),
  ),
  extendedProductComparison: dynamic(
    () =>
      import(
        /* webpackChunkName: 'component-extendedProductComparison' */ '~/shared/components/ExtendedProductComparison'
      ),
  ),
  genericCarousel: dynamic(
    () =>
      import(
        /* webpackChunkName: 'component-genericCarousel' */ '~/shared/components/GenericCarousel'
      ),
  ),
  sumupPartner: dynamic(
    () =>
      import(
        /* webpackChunkName: 'component-sumupPartner' */ '~/shared/components/SumUpPartner'
      ),
  ),
  productPaymentMethods: dynamic(
    () =>
      import(
        /* webpackChunkName: 'component-productPaymentMethods' */ '~/shared/components/ProductPaymentMethods'
      ),
  ),
  columnLayout: dynamic(
    () =>
      import(
        /* webpackChunkName: 'component-columnLayout' */ '~/shared/components/ColumnLayout'
      ),
  ),
  pricingCalculator: dynamic(
    () =>
      import(
        /* webpackChunkName: 'component-pricingCalculator' */ '~/shared/components/PricingCalculator'
      ),
  ),
  multiStepForm: dynamic(
    () =>
      import(
        /* webpackChunkName: 'component-multistep-form' */ '~/domains/sales-leads/components/MultistepForm'
      ),
  ),
  form: dynamic(
    () =>
      import(
        /* webpackChunkName: 'component-form' */ '~/domains/sales-leads/components/FormSection'
      ),
  ),
  trustpilot: dynamic(
    () =>
      import(
        /* webpackChunkName: 'component-trustpilot' */ '~/shared/components/Trustpilot'
      ),
  ),
  supportSection: dynamic(
    () =>
      import(
        /* webpackChunkName: 'component-supportSection' */ '~/shared/components/Support'
      ),
  ),
  expansionPanelList: dynamic(
    // @ts-expect-error Component needs TS rewrite due to prop-types related TS error
    () =>
      import(
        /* webpackChunkName: 'component-expansionPanelList' */ '~/shared/components/ExpansionPanelList'
      ),
  ),
  multiFeatureShowcase: dynamic(
    // @ts-expect-error Component needs TS rewrite due to prop-types related TS error
    () =>
      import(
        /* webpackChunkName: 'component-multiFeatureShowcase' */ '~/shared/components/MultiFeatureShowcase'
      ),
  ),
  pressArticles: dynamic(
    () =>
      import(
        /* webpackChunkName: 'component-pressArticles' */ '~/shared/components/PressArticles'
      ),
  ),
  pressCoverages: dynamic(
    () =>
      import(
        /* webpackChunkName: 'component-pressCoverages' */ '~/shared/components/PressCoverages'
      ),
  ),
  pageCategoriesList: dynamic(
    () =>
      import(
        /* webpackChunkName: 'pageCategoriesList' */ '~/shared/components/PageCategoriesList'
      ),
  ),
  careersPositionsComponent: dynamic(
    () =>
      import(
        /* webpackChunkName: 'component-careersPositions' */ '~/domains/careers/components/CareersPositions'
      ),
  ),
  table: dynamic(
    // @ts-expect-error Component needs TS rewrite due to prop-types related TS error
    () =>
      import(
        /* webpackChunkName: 'component-table' */ '~/shared/components/Table'
      ),
  ),
  inPageNavigation: dynamic(
    () =>
      import(
        /* webpackChunkName: 'component-in-page-navigation' */ '~/shared/components/InPageNavigation'
      ),
  ),
  feeCalc: dynamic(
    () =>
      import(
        /* webpackChunkName: 'component-feeCalc' */ '~/shared/components/FeeCalculator'
      ),
  ),
  multiProductCarousel: dynamic(
    () =>
      import(
        /* webpackChunkName: 'component-feeCalculator' */ '~/shared/components/MultiProductCarousel'
      ),
  ),
  configurator: dynamic(
    () =>
      import(
        /* webpackChunkName: 'component-configurator' */ '~/domains/shop/components/ProductConfigurator'
      ),
  ),
} as ComponentsType;

function getExperiencesContentTypes(section: Section): string[] {
  return (section?.nt_experiences || []).reduce<string[]>(
    (currentExpContentTypes, experience) => {
      const experiencesContentTypes = (experience?.nt_variants || []).reduce<
        string[]
      >(
        (currentVarContentTypes, variant) => [
          ...currentVarContentTypes,
          variant.contentType,
        ],
        [],
      );

      return [...currentExpContentTypes, ...experiencesContentTypes];
    },
    [],
  );
}

function SectionsList({
  sections = [],
  activeProducts = [],
}: SectionsListProps) {
  const { products = {} } = useContext(ProductsContext);
  const productsOnPage = activeProducts.filter(
    (productId) => products[productId],
  );
  const isProductPage = productsOnPage?.length === 1;
  const taggerProperties = useMemo(() => {
    if (!isProductPage) {
      return {};
    }

    const product = products[productsOnPage[0]];

    const currentPrice = product.hasPromo ? product.promoPrice : product.price;
    const currentPriceWithoutCurrency = stripCurrency(currentPrice) as string;

    return {
      'data-elb-product': `name:${product.productId};price:${currentPriceWithoutCurrency}`,
    };
  }, [isProductPage, products, productsOnPage]);

  const componentIds = sections
    .map((section) => {
      // get content types from all the ninetailed experiences variants
      const experiencesContentTypes = getExperiencesContentTypes(section);
      return [...experiencesContentTypes, section.contentType];
    })
    .reduce<string[]>(
      (contentTypesMap, contentTypes) => [
        // return a list of content types without duplications
        ...Array.from(new Set([...contentTypesMap, ...contentTypes])),
      ],
      [],
    );
  const components = pick(componentIds, componentsMap);

  return (
    <div {...taggerProperties}>
      <Sections sections={sections} components={components} />
    </div>
  );
}

/**
 * @component
 */
export default SectionsList;
